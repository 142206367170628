import React from "react";
import RashSelfIcon from "../assets/images/rashmi.svg";
import AboutBox from "../components/About/AboutBox";
import about from "../data/About";
import {
	UserWrapper,
	UserTitle,
	UserDescription,
	DownloadButton,
	UserTopic,
} from "../styles/indexStyle.js";
import Layout from "../components/layout";

const IndexPage = () => {
	return (
		<Layout>
			<UserWrapper>
				<UserTitle>
					Cheryll <span>Gunawan</span>
				</UserTitle>
				<UserDescription>
					<div>
						<p>
							Hello there, welcome to my portfolio. I am an aspiring software engineer!
						</p>
						<DownloadButton
							href="https://drive.google.com/file/d/1_qZ0IFa1MS0bQ9iVGOAgbpym7alwXwX7/view?usp=sharing"
							download
							title="Resume"
						>
							Download Resume
						</DownloadButton>
					</div>
					<img src={RashSelfIcon} alt="icon" />
				</UserDescription>
				<UserTopic>
					{about.map((item) => (
						<AboutBox key={item.id} info={item} />
					))}
				</UserTopic>
			</UserWrapper>
		</Layout>
	);
};

export default IndexPage;
