export default [
	{
		id: 1,
		title: "Experience",
		description:
			"Throughout the past few years, I have had the wonderful opportunity to work with great companies, allowing me to constantly grow as a professional.",
		path: "/experience",
	},
	{
		id: 2,
		title: "Projects",
		description:
			"I enjoy honing my skills as both a programmer and a data analyst with different projects. Here’s a comprehensive list of projects I’ve worked on. ",
		path: "/projects",
	},
	{
		id: 3,
		title: "About Me",
		description:
			"If you're interested in getting to know more about me beyond my achievements and experience, click here! ",
		path: "/aboutme",
	},
];
